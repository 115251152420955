<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">
                결제내역
              </h4>
              <p class="card-title-desc">현재 결제된 내역을 확인합니다.</p>

              <div class=" mg-b-10 fr">
                <form class="d-flex" @submit.prevent="getPaymentListSearch">
                  <select class="form-control mg-r-5">
                    <option value="">결제아이디</option>
                    <option value="">이름</option>
                  </select>
                  <b-form-input
                    id="inline-form-input-name"
                    v-model="serachKeyword"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="검색어"
                  ></b-form-input>
                  <button class="btn btn-primary btn-sm" type="submit">
                    검색
                  </button>
                </form>
              </div>
              <!-- table -->
              <div class="table-responsive">
                <table class="table table-hover mb-0 text-center">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>부트페이ID</th>
                      <th>코스명</th>
                      <th>아이디</th>
                      <th>결제수단</th>
                      <th>상태</th>
                      <th>결제일시</th>
                      <th>관리</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in list" :key="item.idx">
                      <th scope="row">{{ startIndex - index }}</th>
                      <td>{{ item.receiptId }}</td>
                      <td>{{ item.itemName }}</td>
                      <td>{{ item.userId }}</td>
                      <td>{{ item.method }}</td>
                      <td>
                        <span v-if="item.status === '1'" class="badge bg-success"> 결제완료 </span>
                        <span v-else-if="item.status === '101'" class="badge bg-danger"
                          >완전취소</span
                        >
                        <span v-else-if="item.status === '102'" class="badge bg-primary"
                          >일반취소</span
                        >
                        <span v-else-if="item.status === '103'" class="badge bg-warning"
                          >결제취소</span
                        >
                        <span v-else class="badge bg-danger">결제실패</span>
                      </td>
                      <td>{{ item.regdate | moment('YY-MM-DD HH:mm:ss') }}</td>
                      <td>
                        <button
                          class="btn btn-danger btn-sm mr-1"
                          @click="
                            cancelPayment(1, item.receiptId, item.courseId, item.price, item.userId)
                          "
                        >
                          완전취소
                        </button>
                        <button
                          class="btn btn-primary btn-sm mr-1"
                          @click="
                            cancelPayment(2, item.receiptId, item.courseId, item.price, item.userId)
                          "
                        >
                          일반취소
                        </button>
                        <button
                          class="btn btn-warning btn-sm"
                          @click="
                            cancelPayment(3, item.receiptId, item.courseId, item.price, item.userId)
                          "
                        >
                          결제취소
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- //end table -->
              <b-pagination
                v-model="page"
                :total-rows="totalCnt"
                :per-page="limit"
                align="center"
                aria-controls="my-table"
                class="mt-4"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </div>
</template>
<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
export default {
  page: {
    title: 'Payment',
    meta: [{name: 'description', content: appConfig.description}]
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: 'Payment',
      items: [
        {
          text: 'Payment',
          href: '/'
        },
        {
          text: 'List',
          href: '/'
        },
        {
          text: 'Payment Lists',
          active: true
        }
      ],
      serachKeyword: '',
      totalCnt: 0,
      limit: 15,
      page: 1,
      startIndex: 0,
      list: ''
    };
  },
  created() {
    this.paymentList();
  },
  watch: {
    page(v) {
      console.log(v);
      this.paymentList();
    }
  },
  methods: {
    async paymentList() {
      const res = await this.$store.dispatch('payment/getPaymentList', {
        page: this.page,
        limit: this.limit
      });

      this.list = res.data;
      this.totalCnt = res.totalCnt;
      this.startIndex = this.totalCnt - (this.page - 1) * this.limit;
      console.log(this.page, this.limit);
    },
    getPaymentListSearch() {},
    cancelPayment(type, receiptId, courseId, price, userId) {
      let msg;
      if (type === 1) {
        msg = '결제 취소 및 회원 수강 기록 삭제';
      } else if (type === 2) {
        msg = '결제 취소 및 회원 수강 기록 중지 업데이트';
      } else if (type === 3) {
        msg = '결제 취소 및 회원 수강 기록 유지';
      }
      this.$confirm({
        message: msg,
        button: {
          no: '취소',
          yes: '실행'
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: async confirm => {
          if (confirm) {
            // ... do something

            await this.$store
              .dispatch('payment/cancelPayment', {
                courseId,
                receiptId,
                price,
                userId,
                type
              })
              .then(res => {
                this.$confirm({
                  title: res.result ? '취소 성공' : '취소 실패',
                  message: res.message,
                  button: {
                    yes: '확인'
                  }
                });

                if (res.result) {
                  this.paymentList();
                }
              });
          }
        }
      });
    }
  }
};
</script>
<style scoped>
table th,
table td {
  text-align: center;
}
form {
  justify-content: flex-end;
}
form input,
form select {
  width: 100px;
  min-width: 80px;
  margin-right: 10px;
}
form input {
  width: 200px;
}
form button {
  min-width: 50px;
}
.mr-1 {
  margin-right: 5px;
}
</style>
